import { render } from "./DashboardSpaceProductsAndSolutions.vue?vue&type=template&id=822511f8&scoped=true"
import script from "./DashboardSpaceProductsAndSolutions.vue?vue&type=script&lang=js"
export * from "./DashboardSpaceProductsAndSolutions.vue?vue&type=script&lang=js"

import "./DashboardSpaceProductsAndSolutions.vue?vue&type=style&index=0&id=822511f8&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-822511f8"
/* hot reload */
if (module.hot) {
  script.__hmrId = "822511f8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('822511f8', script)) {
    api.reload('822511f8', script)
  }
  
  module.hot.accept("./DashboardSpaceProductsAndSolutions.vue?vue&type=template&id=822511f8&scoped=true", () => {
    api.rerender('822511f8', render)
  })

}

script.__file = "src/components/dashboard/DashboardSpaceProductsAndSolutions.vue"

export default script